
import { Component, Vue } from 'vue-property-decorator'
import { wareHousingList, AssetList } from '@/types/assetScheduling'

@Component
export default class ScheduleAdd extends Vue {
  private info: wareHousingList = {
    orderDate: '',
    projectId: '',
    remarks: '',
    assetList: []
  }

  private page = 1
  private size = 10
  private total = 0

  private rules = {
    projectId: [
      { required: true, message: '请选择项目名称', trigger: ['blur', 'change'] }
    ],
    orderDate: [
      { required: true, message: '请输入入库日期', trigger: ['blur', 'change'] }
    ]
  }

  created () {
    this.getData()
  }

  getData () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetPutLibByOrderId, {
      orderId: this.$route.params.orderId
    }).then(res => {
      this.info = res || []
      this.getList()
    })
  }

  getList () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetImageByPage, {
      page: this.page,
      size: this.size,
      orderId: this.$route.params.orderId
    }).then(res => {
      this.info.assetList = res.list || []
      this.total = res.total || 0
    })
  }
}
